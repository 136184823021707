.App {
    height: 100vh;
    background-image: url("../assets/background.jpeg");
    overflow: scroll;
    text-align: center;
  }
  
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .header-container {
    padding-top: 90px;
  }
  
  .header {
    margin: 0;
    font-size: 50px;
    font-weight: bold;
  }
  
  .sub-text {
    font-size: 25px;
    color: white;
  }
  
  .gradient-text {
    background: -webkit-linear-gradient(left, #a200d6 30%, #35aee2 60%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .cta-button {
    height: 45px;
    border: 0;
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  
  .connect-wallet-button {
    background: -webkit-linear-gradient(left, #a200d6, #35aee2);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
  }
  
  .mint-button {
    background: -webkit-linear-gradient(left, #a200d6, #ff6fdf);
    background-size: 200% 200%;
    animation: gradient-animation 4s ease infinite;
    margin-right: 15px;
  }
  
  .opensea-button {
    background-color: rgb(32, 129, 226);
  }
  
  a {
    text-decoration: none;
  }
  
  .mint-count {
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  
  .body-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }
  
  .body-text {
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
  
  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
  }
  
  .twitter-logo {
    width: 35px;
    height: 35px;
  }
  
  .footer-text {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  
  /* KeyFrames */
  @-webkit-keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }